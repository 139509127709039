import React, { FC, useState } from "react"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image"
export const Footer: FC<{ data: any }> = ({ data }) => {
  const intl = useIntl();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-slogan">
          <StaticImage alt="oldschool" src="../../images/logo.svg" />
          <h3>Boutique Apartments<br />Söll @ Wilder Kaiser</h3>
        </div>
        <div className="footer-contact">
          <div className="pr-lg-5">
            <strong>Julia & Hannes Ager</strong><br />
            Dorf 128, 6306 Söll<br />
            Österreich
          </div>
          <div>
            {' '}<br />
            Tel: <a href="tel:+436641929480 ">0664 / 19 29 480 </a><br />
            E-Mail: <a href="mailto:hallo@oldschool.apartments">hallo@oldschool.apartments</a>
          </div>
        </div>
        <div className="footer-links">
          <ul>
            {intl.locale == 'de' &&
              <li >
                <Link to={'/impressum'} >
                  {intl.formatMessage({ id: 'imprint' })}
                </Link>
              </li>
            }
            {intl.locale == 'en' &&
              <li >
                <Link to={'/imprint'} >
                  {intl.formatMessage({ id: 'imprint' })}
                </Link>
              </li>
            }
            {intl.locale == 'de' &&
              <li >
                <Link to={'/datenschutz'} >
                  {intl.formatMessage({ id: 'dataprotection' })}
                </Link>
              </li>
            }
            {intl.locale == 'en' &&
              <li >
                <Link to={'/dataprotection'} >
                  {intl.formatMessage({ id: 'dataprotection' })}
                </Link>
              </li>
            }

            <li >
              <a href="https://www.facebook.com/oldschoolsoell" target="_blank">
                <StaticImage alt="facebook" src="../../images/facebook.svg" /> Facebook
              </a>
            </li>
            <li >
              <a href="https://www.instagram.com/oldschool_soell/" target="_blank">
                <StaticImage alt="instagram" src="../../images/instagram.svg" /> Instagram
              </a>
            </li>

          </ul>
        </div>
      </div>

      <div className="footer-heavymetal">
        <StaticImage alt="heavymetal" src="../../images/heavymetal.png" />
      </div>
    </footer>
  )
}
