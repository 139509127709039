import React, { FC } from "react"
import { withPrefix, Link } from "gatsby"
import { Navigation } from "../components/Navigation";

export const Layout: FC = ({ children, data }) => {
  return (

    <div>
      <div>
        <Navigation key='navigation' data={data.pageContext.navigation} />
        {children}
      </div>
    </div>

  )
}